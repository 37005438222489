<template>
  <div class="myPopupContent">
    <editor-topbar :title="title" @closeClicked="closeEditor"/>
    <div class="whitePopupContent">
      <div class="err_msg commonError" style="display:none">
        <icon name="fa-exclamation-circle"/>
        <span>{{missingFieldsErrorMessage}}</span>
      </div>
      <div class="commonFormHorScroll" :style="maxHeightStyle">
        <div class="commonColListWrap">
          <div class="commonColList commonColFullList commonColFullListNoMargin
                     marT-10 jUlElementContainer_DynPupup2"
              :class="noOfColumnClass">
            <form autocomplete="off">
              <data-editor-input-field class="column"
                :is-add-mode="isAddMode"
                v-for="item in visibleControls"
                :schema="schemaItemByName(item.fieldName)"
                :key="item.fieldName"
                v-model="editedData[item.fieldName]"
                :isDisabled="isPopupDisabled"
                :should-validate="shouldValidate"
                @validate="inputFieldDidValidate"
              />
            </form>
          </div>
        </div>
      </div>
      <br>
      <div>
        <template v-if="userNameCheck === userNameCheckStates.inactive && isAddMode">
          <button class="lsc-button lsc-rounded-10 green floatRight marginLeft"
                  @click="reactivateUser">{{x('reactivate')}}</button>
        </template>
        <button v-if="missingFieldsErrorMessage.length === 0
                      && isPopupDisabled === false
                      && userNameCheck === userNameCheckStates.available"
                class="lsc-button lsc-rounded-10 green marginLeft floatRight" @click="submitForm"
                type="submit"
                :disabled="isProcessing"
        >
          <icon name="fa-cloud-upload"/>
          {{ x('save') }}
        </button>
        <button class="lsc-button lsc-rounded-10 red floatRight" type="submit" @click="closeEditor"
                :disabled="isProcessing"
        >
          <icon name="fa-close"/>
          {{ x('cancel') }}
        </button>
        <template v-if="userNameCheck === userNameCheckStates.inactive && isAddMode">
          <p>{{x('userNamePreviouslyUsedReactivate')}}</p>
        </template>
        <p v-if="userNameCheck === userNameCheckStates.active ||
                 userNameCheck === userNameCheckStates.inactive && !isAddMode">
          {{x('userNameInUse')}}
        </p>
        <div class="lsc-clear"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { debounce, kebabCase } from 'lodash';
  import translate from '@/components/Mixins/Translate';
  import DataEditorInputField from '@/components/Announcements/Editor/DataEditorInputField';
  import { httpPost, httpPut, httpGet } from '@/classes/httpHelper';
  import EditorTopbar from '@/components/Announcements/Editor/EditorTopBar';

  const userNameCheckStates = {
    available: 'available',
    active: 'active',
    inactive: 'inactive',
  };

  export default {
    name: 'data-editor',
    components: { EditorTopbar, DataEditorInputField },
    mixins: [translate],
    emits: ['editorIsReady', 'columnClassComputed'],
    data() {
      return {
        userNameCheckStates,
        elementHeightRequirement: 100,
        missingFields: [],
        editedData: {}, // local copy of the item, for editing.

        isEditorReady: false,
        isPopupDisabled: false, // Indicates that the entire popup is disabled and cannot be edited.

        // This property is a trigger that causes datepicker fields to retrieve data
        // (it uses jquery and is not reactive),
        // and all input fields to validate and show any error messages.
        // Remember to await this.$nextTick() to let its effects take place.
        shouldValidate: false,
        validatesOk: true,
        // State of user name check. Can be either 'available', 'active' or 'inactive'
        // specific server check to see if the chosen user name has
        // been used previously. If yes, it cannot be used again, but the old user can be re-activated.
        // This only applies to dataType = users.
        userNameCheck: userNameCheckStates.available,
        // Indicates if a server request is being performed. While this is true, submit buttons
        isProcessing: false,
      };
    },
    computed: {
      availableHeight() {
        const whitePopupHeight = 30; // marTop=15 & marBot=15
        const subGridHeight = 50;
        const whitePopupContentHeight = 50; // padTop=20 & padBot=20 & borTop=5 & borBot=5
        const buttonDivHeight = 37;
        let availableHeight = $(window).height() - whitePopupHeight - subGridHeight
          - whitePopupContentHeight - buttonDivHeight - 20; // buffer height 20 to tackle common error

        if (availableHeight < 250) {
          availableHeight = 250;
        }
        return availableHeight;
      },
      fieldsPerColumn() {
        const fieldCount = Math.round(this.availableHeight / this.elementHeightRequirement);
        return Math.max(fieldCount, 3);
      },
      columnCount() {
        if (this.visibleControls.length < this.fieldsPerColumn) return 1;
        if (this.visibleControls.length < this.fieldsPerColumn * 2) return 2;
        return 3;
      },
      noOfColumnClass() {
        switch (this.columnCount) {
          case 1: return 'oneColList';
          case 2: return 'twoColList';
          default: return 'threeColList';
        }
      },
      maxHeightStyle() {
        return { maxHeight: `${this.availableHeight}px` };
      },
      visibleControls() {
        return this.editorData.dataSchema.filter((item) => item.userVisible);
      },
      isAddMode() {
        return !this.editorData.id;
      },
      title() {
        if (this.editedData.name !== undefined) {
          return this.editedData.name;
        }

        if (this.editedData.firstName !== undefined && this.editedData.lastName !== undefined) {
          return `${this.editedData.firstName} ${this.editedData.lastName}`;
        }

        return this.x(this.editorData.createPhrase);
      },
      missingFieldsErrorMessage() {
        if (this.missingFields.length === 0) return '';
        const fields = this.missingFields.join(', ');
        return `${this.x('editViewMissingField')} (${fields})`;
      },
      ...mapState(['config', 'user']),
      ...mapState('DataEditorStore', ['editorData']),
    },
    watch: {
      columnCount: {
        handler(newValue) {
          console.log(`column count: ${newValue}`);
          switch (newValue) {
            case 1: this.$emit('columnClassComputed', 'max-Width350'); break;
            case 2: this.$emit('columnClassComputed', 'max-Width480'); break;
            default: this.$emit('columnClassComputed', 'max-Width950'); break;
          }
        },
        immediate: true,
      },
    },
    created() {
      // This throttles the verifyUsername function so that it can not be called more than
      // once every 500 ms
      this.debouncedVerifyUsername = debounce(this.verifyUsername, 500);
    },
    mounted() {
      this.initialize();
    },
    methods: {
      initialize() {
        const data = this.editorData;
        this.editedData = { ...data.item };

        this.missingFields = this.validateItemAgainstSchema(data.item, data.dataSchema);
        if (this.missingFields.length > 0) {
          console.warn('Missing fields: ', this.missingFields);
        }

        this.$emit('editorIsReady');
        this.isEditorReady = true;
        this.$store.commit('DataEditorStore/setIsLoading', false);

        if (this.editorData.dataType === 'Users') {
          this.setupWatchUserName();
        }
      },

      /**
       * This watch is set this way because it is conditional. It should only be activated
       * when the data type is 'user'.
       */
      setupWatchUserName() {
        // eslint-disable-next-line no-unused-vars
        this.$watch(() => this.editedData.username, (value) => {
          this.debouncedVerifyUsername(value);
        });
      },

      async verifyUsername(userName) {
        if (!userName) return;
        if (userName === this.editorData.item.username) {
          // No errors if the unedited user name has been chosen
          this.userNameCheck = this.userNameCheckStates.available;
          return;
        }
        console.log('verify username:', userName);
        const result = await httpGet(`users/exists/${userName}`);
        this.userNameCheck = result.state;
        console.log(result.state);
      },

      /** Checks that there is data for all fields, returns an array of missing fields. */
      validateItemAgainstSchema(item, schema) {
        const missingFields = [];
        for (const schemaItem of schema) {
          if (!(schemaItem.fieldName in item)) {
            missingFields.push(schemaItem.translationsName !== null
              ? this.x(schemaItem.translationsName) : schemaItem.fieldName);
          }
        }
        return missingFields;
      },

      inputFieldDidValidate(message) {
        console.log('Input field validation: ', message);
        if (message !== '') {
          if (this.validatesOk) this.validatesOk = false;
        }
      },

      schemaItemByName(fieldName) {
        if (!this.editorData) return null;
        const items = this.editorData.dataSchema.filter((item) => item.fieldName === fieldName);
        if (items.length > 0) return items[0];
        return null;
      },

      closeEditor() {
        this.$store.commit('DataEditorStore/clearEditorData');
        this.$store.commit('DataEditorStore/closeEditor');
      },

      async submitForm() {
        if (this.isProcessing) return;
        this.isProcessing = true;

        // This should be set to true here, and when shouldValidate is set, all fields will
        // validate, and it might be set to false again.
        this.validatesOk = true;
        // Triggers validation and datepicker value retrieval
        this.shouldValidate = true;

        // This gives vue a chance to update fields, run watchers and trigger the validation.
        await this.$nextTick();

        if (!this.validatesOk) {
          this.isProcessing = false;
          return;
        }

        const result = await this.dataEditorSave();

        if (result.errorMessage !== '') {
          console.error(result.errorMessage);
          this.isProcessing = false;
          return;
        }

        if (this.isAddMode) {
          // Should reference DataTable component
          this.editorData.rowComponent.updateAfterCreate(result.data);
        } else {
          // Should reference DataRow component
          this.editorData.rowComponent.updateAfterEdit(result.data);
        }
        this.closeEditor();
      },

      async dataEditorSave() {
        this.editedData.origin = 'admin';
        try {
          const postData = JSON.stringify(this.editedData);
          const dataType = this.editorData.dataType;
          const result = this.isAddMode
            ? await httpPost(kebabCase(this.editorData.dataType), postData)
            : await httpPut(`${kebabCase(dataType)}/${this.editedData.id}`, postData);

          if (result.error) {
            return {
              errorMessage: result.error.message,
              data: null,
            };
          }
          return {
            errorMessage: '',
            data: result,
          };
        } catch (e) {
          console.error(e);
          return {
            errorMessage: 'An error occurred during save.',
            data: null,
          };
        }
      },

      async reactivateUser() {
        if (this.isProcessing) return;
        this.isProcessing = true;
        const userName = this.editedData.username;
        try {
          const reactivatedUser = await httpPost(`users/undelete/${userName}`, {});
          this.editorData.rowComponent.updateAfterCreate(reactivatedUser);
          this.closeEditor();
        } catch (e) {
          console.error(e);
          this.isProcessing = false;
        }
      },
    },
  };
</script>

<style scoped>
  .marginLeft {
    margin-left: 15px;
  }

  .floatRight {
    float: right;
  }
</style>
